
































































































































































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapGetters } from 'vuex';
import { IContacto } from '@/store/types/empresa';
import ContactLeftPanel from '@/components/contact/ContactLeftPanel.vue';	

export default Vue.extend({
	name: 'BookingForm',
	data() {
		return {
			posting: false,
			msgPoliticas: '',
			contacto: {} as IContacto,
			galleryDir: 'images/galeria/',
			fontColor: '',
			paginationEnabled: true			
		}
	},
	components: {
		'ha-contact-left-panel': ContactLeftPanel
	},
	computed: {
		...mapGetters('imagenes', {
			isLoaded: 'GET_IS_LOADED',
			isLoading: 'GET_IS_LOADING',
			tarjetasS1E3: 'GET_TARJETAS_S1_E3',
			header: 'GET_TARJETAS_S4_E5', // HEADER
		}),
		...mapGetters('empresa', {
			mensaje: 'GET_MENSAJE',
			exitoReservacion: 'GET_EXITO_RESERVACION',
			reservacion: 'GET_RESERVACION',
			getTotal: 'GET_TOTAL_RESERVACION',
			archivosEmpresa: 'GET_ARCHIVOS_EMPRESA'
		})
	},
	mounted() {
		if (this.isLoaded === false && this.isLoading === false) {

			this.$store.dispatch('imagenes/doGetTarjetas').then((response: any) => {
				let ok = true

				// console.log(response)
				if (response && response.data && response.data !== null && response.data.codigo === 20000) {
					ok = true
				} else if (response && response.data && response.data !== null) {
					ok = true
				} else {
					ok = false
				}
			})
		}
		
	},
	methods: {
		getFontColor() {
			if (this.fontColor === '') {
				this.fontColor = 'color:#' + this.header[0].descuento.toString(16) + ' !important';
			}
			return this.fontColor;
		},
		checkIfNumberOptionAppliesForBookingRoom(howMany: number): boolean {
			const howMuch = this.reservacion && this.reservacion.room && this.reservacion.room.link ? parseInt(this.reservacion.room.link, 10) : 0

			if (howMany <= howMuch) {
				return true
			} else {
				return false
			}
		},
		submitForm() {

			this.msgPoliticas = ''
			this.posting = true

			if (this.reservacion.politicas_leidas === false) {
				if ($i18n.locale === 'es') { this.msgPoliticas = 'Favor de aceptar las políticas de privacidad para continuar'; }
				if ($i18n.locale === 'en') { this.msgPoliticas = 'Please check the privacy policies to continue'; }
				this.posting = false
				return;
			}

			try {

				this.contacto.message = (this.contacto.message === null || this.contacto.message === '') ? '.' : this.contacto.message;

				if (this.contacto.email === null || this.contacto.email === '' || this.reservacion.room.id === null || this.reservacion.breakfast === null || this.reservacion.breakfast === '') {
					if ($i18n.locale === 'es') { this.msgPoliticas = 'Favor de capturar todos los datos para continuar'; }
					if ($i18n.locale === 'en') { this.msgPoliticas = 'Please fill the form completely'; }
					this.posting = false
					return;
				}

			} catch (e) {
				if ($i18n.locale === 'es') { this.msgPoliticas = 'Favor de capturar todos los datos para continuar'; }
				if ($i18n.locale === 'en') { this.msgPoliticas = 'Please fill the form completely'; }
				this.posting = false
				return;
			}

			this.contacto.language = $i18n.locale
			this.reservacion.contacto = this.contacto



			this.$store.dispatch('empresa/doPostReservacion', this.reservacion).then(() => {
				//this.mensaje = this.$store.getters['empresa/GET_MENSAJE'] // use [] in getters
				this.posting = false;
				if (this.exitoReservacion === true) {
					this.$router.push('bookingSuccess')
				} else {
					if ($i18n.locale === 'es') { this.msgPoliticas = 'Ha habido un error en el contacto de reservacion, favor de usar otro medio de contacto listado a la izquierda'; }
					if ($i18n.locale === 'en') { this.msgPoliticas = 'There has been an error, please use another medium of contact or booking with us listed at your left'; }
				}
			});
			this.posting = false
		}
	}
});
