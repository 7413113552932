










































































// @ is an alias to /src
import Vue from 'vue';
import $i18n from '../../i18n';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'Booking',
	computed: {
		...mapGetters('empresa', {
			mensaje: 'GET_MENSAJE',
			reservacion: 'GET_RESERVACION',				
		})
	},
	methods: {
		booking() {
			this.$router.push('booking')
		}
	}
});
